<template>
  <div class="searchSortHull">
    <input class="search" type="text" :tabindex="1" v-model="searchTerm" @keyup.enter="changeSearchTerm()" :placeholder="$t('action.search')">
    <div class="sortSelector" :tabindex="2" @blur="open = false">
      <div class="sortSelected" :class="{ 'open': open }" @click="open = !open">
        {{ $t(`sorting.${sortType}`) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-sort"/>&nbsp;{{ $t('action.sort') }}
      </div>
      <div class="sortSelection" v-if="open">
        <div v-for="(sortType, id) of sortTypes" :key="id" @click="changeSortType(sortType); open = false">
          {{ $t(`sorting.${sortType}`) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
export default {
  name: 'SearchSortComponent',
  props: {
    sortTypes: {
      type: Array,
      default: () => (['createdAsc', 'createdDesc', 'modifiedAsc', 'modifiedDesc']),
    },
  },
  emits: ['searchTerm', 'sortType'],
  data() {
    return {
      open: false,
      searchTerm: '',
      sortType: localStorage.getItem('col_as') ?? 'modifiedDesc',
    };
  },
  mounted() {
  },
  methods: {
    changeSearchTerm() {
      this.$emit('searchTerm', this.searchTerm);
    },
    changeSortType(sortType) {
      this.sortType = sortType;
      this.$emit('sortType', sortType);
    },
  },
};
</script>
<style scoped>

.search {
  display: inline-block;
  border: 2px solid var(--color_brown);
  color: black;
  padding: 6px 15px;
  background-color: white;
  border-radius: 20px 0 0 20px;
  border-right: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: right;
  margin: 0;
  width: 100px;
}

.sortSelector {
  position: relative;
  display: inline-block;
  font-size: 18px;
  vertical-align: top;
}

.sortSelected {
  cursor: pointer;
  color: var(--color_brown);
  border: 2px solid var(--color_brown);
  padding: 6px 15px;
  background-color: var(--color_almost_white);
  border-radius: 0 20px 20px 0;
  line-height: 32px;
  text-align: center;
}
.sortSelected.open {
  border-radius: 0 20px 0 0;
}

.sortSelection {
  color: var(--color_brown);
  border-radius: 0px 0px 20px 20px;
  overflow: hidden;
  border-right: 2px solid var(--color_brown);
  border-left: 2px solid var(--color_brown);
  border-bottom: 2px solid var(--color_brown);
  position: absolute;
  background-color: var(--color_almost_white);
  left: 0;
  right: 0;
  text-align: left;
  z-index: 1;
}
.sortSelection div {
  color: var(--color_brown);
  cursor: pointer;
  padding: 6px 15px;
}

@media (max-width: 800px) {
  .search {
    border-radius: 20px;
    font-size: 14px;
    left: 0;
    right: 0;
    width: calc(100% - 34px);
    text-align: left;
    border-right: 2px solid var(--color_brown);
    line-height: 18px;
  }

  .sortSelector {
    border-radius: 20px;
    width: 100%;
    font-size: 14px;
    margin-top: 4px;
    line-height: 18px;
  }

  .sortSelected {
    border-radius: 20px;
    text-align: left;
    line-height: 18px;
  }
}
</style>
