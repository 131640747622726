<template>
  <div class="paginationHull">
    <div class="pageSizeHull" :tabindex="99" @blur="pageSizeOpen = false">
      <div class="pageSize" :class="{ 'pageSizeOpen': pageSizeOpen }" @click="pageSizeOpen = !pageSizeOpen">
        {{ pageSize }}
        {{ $t('pagination.pageSize') }}
      </div>
      <div class="pageSizeSelection" v-if="pageSizeOpen">
        <div v-for="(size, id) of pageSizes" :key="id" @click="changePageSize(size); pageSizeOpen = false">
          {{ size }}
        </div>
      </div>
    </div>
    <div class="pageHull">
      <div class="leftHull" v-if="page > 0">
        <div class="page first" @click="changePage(0)"><i class="fas fa-angle-double-left"/></div>
        <div class="page left" v-if="page > 1" @click="changePage(page-1)"><i class="fas fa-angle-left"/></div>
      </div>
      <div class="page middle" :class="{ 'left': page == 0, 'right': page == pages-1 }" @click="pageInputOpen = !pageInputOpen">
        <input class="pageInput" ref="pageInput" type="number" v-if="pageInputOpen" @click.stop :value="page+1"
          @blur="changePage(Math.min(pages-1, Math.max(0, Number($event.target.value)-1))); pageInputOpen = false"
          @keyup.enter="changePage(Math.min(pages-1, Math.max(0, Number($event.target.value)-1))); pageInputOpen = false"
          :placeholder="page+1"
        />
        <span v-else>{{ page+1 }}</span>
        {{ $t('pagination.pageOf') }}
        {{ pages }}
      </div>
      <div class="rightHull" v-if="page < pages-1">
        <div class="page right" v-if="page < pages-2" @click="changePage(page+1)"><i class="fas fa-angle-right"/></div>
        <div class="page last" @click="changePage(pages-1)"><i class="fas fa-angle-double-right"/></div>
      </div>
    </div>
    <div class="elementCountHull">
      <div class="elementCount">
        {{ $t(elementLabels[Math.min(pageSize, elementCount - (page * pageSize)) === 1 ? 0 : 1]) }}
        {{ page * pageSize + 1 }}
        <span v-if="Math.min(pageSize, elementCount - (page * pageSize)) === 2">
          {{ $t('pagination.paginationAnd') }}
          {{ page * pageSize + Math.min(pageSize, elementCount - (page * pageSize)) }}
        </span>
        <span v-if="Math.min(pageSize, elementCount - (page * pageSize)) > 2">
          {{ $t('pagination.paginationTo') }}
          {{ page * pageSize + Math.min(pageSize, elementCount - (page * pageSize)) }}
        </span>
        {{ $t('pagination.paginationOf') }}
        {{ elementCount }}
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
export default {
  name: 'PaginationComponent',
  props: {
    pageSizes: {
      type: Array,
      default: () => ([8, 16, 32]),
    },
    elementCount: {
      type: Number,
      default: 1,
    },
    elementLabels: {
      type: Array,
      default: () => (['pagination.element', 'pagination.elements']),
    },
  },
  emits: ['page', 'pageSize'],
  data() {
    return {
      pageSizeOpen: false,
      pageInputOpen: false,
      page: 0,
      pageSize: this.pageSizes[0],
    };
  },
  watch: {
    pageInputOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.pageInput.focus();
        });
      }
    },
  },
  computed: {
    pages() {
      return Math.ceil(this.elementCount / this.pageSize);
    },
  },
  mounted() {
    const defaultPageSize = this.$store.getters.currentPageSize;
    if (defaultPageSize != null && defaultPageSize > -1 && this.pageSizes.indexOf(defaultPageSize) > -1) {
      this.changePageSize(defaultPageSize);
    } else {
      this.changePageSize(this.pageSizes[0]);
    }
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.$emit('page', page);
    },
    changePageSize(pageSize) {
      this.changePage(0);
      this.pageSize = pageSize;
      this.$store.commit('setPageSize', pageSize);
      this.$emit('pageSize', pageSize);
    },
  },
};
</script>
<style scoped>

.paginationHull {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
}

.pageSizeHull {
  position: relative;
  font-size: 18px;
  grid-column: 1;
  grid-row: 1;
  margin-right: auto;
}

.pageSize {
  cursor: pointer;
  color: var(--color_brown);
  border: 2px solid var(--color_brown);
  padding: 6px 15px;
  background-color: var(--color_almost_white);
  border-radius: 20px;
  line-height: 32px;
  text-align: center;
}
.pageSize.pageSizeOpen {
  border-radius: 0 0 20px 20px;
}

.pageSizeSelection {
  color: var(--color_brown);
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  border-right: 2px solid var(--color_brown);
  border-left: 2px solid var(--color_brown);
  border-top: 2px solid var(--color_brown);
  position: absolute;
  background-color: var(--color_almost_white);
  left: 0;
  right: 0;
  bottom: 48px;
  text-align: left;
  z-index: 1;
}
.pageSizeSelection div {
  color: var(--color_brown);
  cursor: pointer;
  padding: 6px 15px;
}

.pageHull {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  font-size: 18px;
  margin: auto;
  grid-column: 2;
  grid-row: 1;
}

.page {
  display: inline-block;
  padding: 6px 15px;
  line-height: 32px;
  text-align: center;
  color: var(--color_brown);
  cursor: pointer;
}
.page.middle {
  border: 2px solid var(--color_brown);
  grid-column: 2;
  position: relative;
}
.page.middle:not(.left):not(.right) {
  border-left: none;
  border-right: none;
}
.page.middle.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.page.middle.right:not(.left) {
  border-left: none;
}
.page.middle.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.page.middle.left:not(.right) {
  border-right: none;
}
.pageInput {
  display: inline-block;
  color: var(--color_brown);
  overflow: hidden;
  background-color: var(--color_almost_white);
  text-align: right;
  width: 50px;
  line-height: 24px;
  border: none;
}
.page:not(.middle).left {
  border-left: 2px solid var(--color_brown);
}
.page:not(.middle).right {
  border-right: 2px solid var(--color_brown);
}
.leftHull {
  border: 2px solid var(--color_brown);
  border-right: none;
  grid-column: 1;
  margin-left: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.rightHull {
  border: 2px solid var(--color_brown);
  border-left: none;
  grid-column: 3;
  margin-right: auto;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.elementCountHull {
  font-size: 18px;
  grid-column: 3;
  grid-row: 1;
  margin-left: auto;
}

.elementCount {
  color: var(--color_brown);
  border: 2px solid var(--color_brown);
  padding: 6px 15px;
  background-color: var(--color_almost_white);
  border-radius: 20px;
  line-height: 32px;
  text-align: center;
}

@media (max-width: 640px) {

  .paginationHull {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }

  .pageSizeHull {
    grid-column: 1;
    grid-row: 1;
    margin: auto;
  }

  .pageHull {
    grid-column: 1;
    grid-row: 2;
    margin: 5px auto auto auto;
  }
  .page {
    font-size: 14px;
    padding: 6px 12px;
  }

  .elementCountHull {
    display: none;
  }
}
</style>
