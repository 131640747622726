/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */
const ServiceHelper = require('./ServiceHelper');
const CryptoHelper = require('./CryptoHelper');
const ReportHelper = require('./ReportHelper');
const Constants = require('./Constants');

const addFieldToCSV = (csvContentSoFar, value, fieldWrapper, fieldSeparator) => {
  console.log('addFieldToCSV', value);
  let csvContent = csvContentSoFar;
  csvContent += fieldWrapper;
  csvContent += String(value).replace(/"/g, '\'');
  csvContent += fieldWrapper;
  csvContent += fieldSeparator;
  return csvContent;
};

module.exports = {
  performCSVExport: async (reportConfigId, startDate, endDate, fieldWrapper, fieldSeparator, lineSeparator, inFieldSeparator, i18n, language, filter) => {
    const reportingData = await ServiceHelper.loadDataFromBackend(`reportingData/${reportConfigId}/${startDate.getTime()}/${endDate.getTime()}`, { sorting: filter });
    // check for empty list!
    // if (reportingData.payload.length === 0) return;

    const configData = await ServiceHelper.loadDataFromBackend(`reportConfig/${reportConfigId}`);
    const config = JSON.parse(CryptoHelper.decrypt(configData.payload.content));
    const { fallbackLanguage } = config;
    const reports = await Promise.all(
      reportingData.payload.map(async (r) => ({
        ...r,
        name: CryptoHelper.decrypt(r.name),
        content: JSON.parse(CryptoHelper.decrypt(r.content)),
      })),
    );

    let csvContent = '';

    csvContent = addFieldToCSV(csvContent, i18n('csv.reportName'), fieldWrapper, fieldSeparator);
    csvContent = addFieldToCSV(csvContent, i18n('csv.lastModified'), fieldWrapper, fieldSeparator);
    csvContent = addFieldToCSV(csvContent, i18n('csv.state'), fieldWrapper, fieldSeparator);
    config.fields.forEach((f) => {
      let label = f.title != null && f.title[language] != null ? f.title[language] : null;
      if (label == null) {
        label = f.title != null && f.title[fallbackLanguage] != null ? f.title[fallbackLanguage] : f.name;
      }

      if ([Constants.FIELD_TYPES.textfield, Constants.FIELD_TYPES.textarea, Constants.FIELD_TYPES.checkbox, Constants.FIELD_TYPES.dropdown, Constants.FIELD_TYPES.date, Constants.FIELD_TYPES.list, Constants.FIELD_TYPES.table].includes(f.type)) {
        csvContent = addFieldToCSV(csvContent, label, fieldWrapper, fieldSeparator);
      } else if (f.type === Constants.FIELD_TYPES.action && ReportHelper.getActionType(f) === Constants.ACTION_TYPES.locatePosition) {
        // gps
        csvContent = addFieldToCSV(csvContent, `${(label)} [lat, lon]`, fieldWrapper, fieldSeparator);
      }
    });
    csvContent = addFieldToCSV(csvContent, i18n('csv.photos'), fieldWrapper, fieldSeparator);
    csvContent = addFieldToCSV(csvContent, i18n('csv.voices'), fieldWrapper, fieldSeparator);
    csvContent += lineSeparator;

    reports.forEach((r) => {
      // name
      csvContent = addFieldToCSV(csvContent, r.name, fieldWrapper, fieldSeparator);
      // modified
      csvContent = addFieldToCSV(csvContent, new Date(r.modified).toLocaleString(), fieldWrapper, fieldSeparator);
      // state
      csvContent = addFieldToCSV(csvContent, [i18n('detail.state0'), i18n('detail.state1'), i18n('detail.state2')][(r.state != null ? r.state : 0)], fieldWrapper, fieldSeparator);
      // prepare values by fieldName
      const values = {};
      r.content.assigned_values.forEach((v) => {
        values[v.valueKey] = v.value;
      });

      config.fields.forEach((f) => {
        const val = values[f.name];
        if ([Constants.FIELD_TYPES.textfield, Constants.FIELD_TYPES.textarea, Constants.FIELD_TYPES.checkbox, Constants.FIELD_TYPES.dropdown].includes(f.type)) {
          csvContent = addFieldToCSV(csvContent, val != null ? val : '', fieldWrapper, fieldSeparator);
        } else if (f.type === Constants.FIELD_TYPES.list) {
          csvContent = addFieldToCSV(csvContent, val != null ? ReportHelper.getListValueForField(values, f.name, ', ') : '', fieldWrapper, fieldSeparator);
        } else if (f.type === Constants.FIELD_TYPES.table) {
          csvContent = addFieldToCSV(csvContent, val != null ? ReportHelper.getTableValueForField(values, f.name, JSON.parse(Buffer.from(f.special, 'base64')), ', ', false) : '', fieldWrapper, fieldSeparator);
        } else if (f.type === Constants.FIELD_TYPES.date) {
          csvContent = addFieldToCSV(csvContent, val != null ? ReportHelper.getDateValueForField(values, f.name, i18n, ', ', inFieldSeparator) : '', fieldWrapper, fieldSeparator);
        } else if (f.type === Constants.FIELD_TYPES.action && ReportHelper.getActionType(f) === Constants.ACTION_TYPES.locatePosition) {
          // GPS
          let gpsVal = ReportHelper.getGPSValueForField(f, values);
          if (gpsVal == null) gpsVal = '';
          csvContent = addFieldToCSV(csvContent, gpsVal, fieldWrapper, fieldSeparator);
        }
      });

      const url = new URL(window.location.href);
      // eslint-disable-next-line prefer-template
      const baseUrl = `${url.protocol}//${url.hostname}${(url.port != null && url.port.length > 0 ? ':' + url.port : '')}?attachmentId=`;

      let photoStr = '';
      if (r.photos != null) {
        r.photos.forEach((p) => {
          if (photoStr.length > 0) photoStr += inFieldSeparator;
          photoStr += `${baseUrl}${p._id}`;
          if (p.comment != null && p.comment.trim().length > 0) {
            photoStr += ` (${CryptoHelper.decrypt(p.comment)})`;
          }
        });
      }
      csvContent = addFieldToCSV(csvContent, photoStr, fieldWrapper, fieldSeparator);

      let voiceStr = '';
      if (r.voices != null) {
        r.voices.forEach((v) => {
          if (voiceStr.length > 0) voiceStr += inFieldSeparator;
          voiceStr += `${baseUrl}${v._id}`;
        });
      }
      csvContent = addFieldToCSV(csvContent, voiceStr, fieldWrapper, fieldSeparator);

      csvContent += lineSeparator;
    });

    const blob = new Blob([Buffer.from(csvContent, 'utf-8')], { type: 'text/csv' });
    const objUrl = URL.createObjectURL(blob);
    // download
    const encodedUri = encodeURI(objUrl);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${encodeURI(config.name)}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
    link.remove();
  },
};
