<template>
  <div v-if="reportConfig != null">
    <div class="chartSettings">
      <div class="label">{{ $t('charts.from') }}:</div>
      <div class="value"><Datepicker v-model="startDate" @update:modelValue="pickerClosed"/></div>
    </div>
    <div class="chartSettings">
      <div class="label">{{ $t('charts.to') }}:</div>
      <div class="value"><Datepicker v-model="endDate" @update:modelValue="pickerClosed"/></div>
    </div>
    <div class="chartSettings">
      <div class="label">{{ $t('charts.reportField') }}:</div>
      <div class="value">
        <select v-model="selectedReportField" @change="fieldChanged">
          <option v-for="r in reportFields" :key="r.val" :value="r.val">{{r.label}}</option>
        </select>
      </div>
    </div>
    <div class="chartSettings">
      <div class="label">{{ $t('charts.chartType') }}:</div>
      <div class="value">
        <select v-model="chartType">
          <option value='bar'>{{$t('charts.type_bar')}}</option>
          <option value='doughnut'>{{$t('charts.type_doughnut')}}</option>
          <option value='pie'>{{$t('charts.type_pie')}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="chartWrapper" v-if="showChart">
    <BarChart v-if="chartType == 'bar'" :chartData="chartData" ref="chart" :options="chartOptions[chartType]"/>
    <DoughnutChart v-if="chartType == 'doughnut'" :chartData="chartData" ref="chart" :options="chartOptions[chartType]"/>
    <PieChart v-if="chartType == 'pie'" :chartData="chartData" ref="chart" :options="chartOptions[chartType]"/>
  </div>

</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CryptoHelper from '@/helpers/CryptoHelper';
import ServiceHelper from '@/helpers/ServiceHelper';
import Constants from '@/helpers/Constants';
import Datepicker from 'vue3-datepicker';
import LanguageHelper from '@/helpers/LanguageHelper';
import { Chart, registerables } from 'chart.js';
import { BarChart, DoughnutChart, PieChart } from 'vue-chart-3';

Chart.register(...registerables);

export default {
  name: 'ReportChartsComponent',
  props: ['reportConfig'],
  components: {
    Datepicker,
    BarChart,
    PieChart,
    DoughnutChart,
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      valueWrapper: '"',
      chartType: 'bar',
      languages: [],
      selectedReportField: null,
      languageLabels: LanguageHelper.getStandardReportLanguages(),
      language: LanguageHelper.getBrowserLocale(),
      filter: '',
      reportFields: [],
      checkboxGroupsByFieldNames: null,
      fieldTitlesByKey: {},
      fieldColorsByKey: {},
      firstRender: true,
      showChart: false,
      defaultColors: ['#3ab6aa', '#2e8a8a', '#3067bb', '#1d4d95', '#95dce6', '#6cb1bc', '#fac96d', '#d2a551', '#ed8962', '#ca7553'],
      chartOptions: {
        bar: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          ticks: {
            precision: 0,
          },
        },
        pie: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
          ticks: {
            precision: 0,
          },
        },
        doughnut: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
          ticks: {
            precision: 0,
          },
        },
      },
      chartData: {},
      reports: [],
    };
  },
  computed: {
    compChartData() {
      return this.chartData;
    },
  },
  created() {
    this.startDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.endDate = new Date();
    this.reportFields = [{ label: this.$t('charts.state'), val: '__state', type: 'STATE' }];
    this.selectedReportField = '__state';
    if (this.reportConfig != null) {
      this.parseConfig();
      this.fetchData();
    }
  },
  mounted() {
    const now = new Date();
    this.endDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59));
  },
  watch: {
    reportConfig() {
      this.parseConfig();
      this.fetchData();
    },
  },
  methods: {
    pickerClosed() {
      this.fetchData();
    },
    parseConfig() {
      if (this.reportConfig == null) return;
      const reportFields = [{ label: this.$t('charts.state'), val: '__state', type: 'STATE' }];
      const config = JSON.parse(CryptoHelper.decrypt(this.reportConfig.content).toString('utf-8'));
      this.language = config != null ? config.languageFallback : LanguageHelper.getBrowserLocale();
      this.languages = config != null ? config.languages : [];
      const groupNames = [];
      const groups = {};
      const checkboxGroupsByFieldNames = {};
      config.fields.forEach((f) => {
        this.fieldTitlesByKey[f.name] = f.title[this.language];
        if (f.type === Constants.FIELD_TYPES.dropdown) {
          reportFields.push({ label: f.title[this.language], val: f.name, type: 'DD' });
        } else if (f.type === Constants.FIELD_TYPES.checkbox) {
          let group = null;
          try {
            const decoded = Buffer.from(f.special, 'base64');
            group = JSON.parse(decoded).groupname;
            const fieldConfig = f;
            // store groupname and field title for usage in chart
            fieldConfig.group = group;
            const c = JSON.parse(decoded).chartcolor;
            this.fieldColorsByKey[f.name] = (c != null && c !== '') ? c : null;
            fieldConfig.value = f.title[this.language];
          } catch (e) {
            console.error(e);
          }
          if (group != null && group !== '') {
            group = group.trim();
            if (!groupNames.includes(group)) {
              reportFields.push({ label: group, val: group, type: 'GROUP' });
              groupNames.push(group);
              groups[group] = [{ label: f.title[this.language], val: f.name }];
            } else {
              groups[group].push({ label: f.title[this.language], val: f.name });
            }
            checkboxGroupsByFieldNames[f.name] = group;
          } else {
            reportFields.push({ label: f.title[this.language], val: f.name, type: 'CB' });
          }
        }
      });
      this.checkboxGroupsByFieldNames = checkboxGroupsByFieldNames;
      this.reportFields = reportFields;
    },
    confirm() {
      this.fetchData();
    },
    async fetchData() {
      const endDate = new Date(Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 23, 59, 59));
      const startDate = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), 0, 0, 0));
      const reportingData = await ServiceHelper.loadDataFromBackend(`reportingData/${this.reportConfig._id}/${startDate.getTime()}/${endDate.getTime()}`, { sorting: this.filter });
      this.reports = reportingData.payload;
      this.processData();
    },
    fieldChanged() {
      this.processData();
    },
    processData() {
      let fieldForChart = this.reportFields[0];
      const fields = this.reportFields.filter((f) => f.val === this.selectedReportField);
      if (fields.length === 1) {
        [fieldForChart] = fields;
      }
      const dataByKey = {};
      const colors = [];
      const chartData = {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      };
      this.reports.forEach((r) => {
        try {
          if (fieldForChart.type === 'STATE') {
            const s = this.$t(`detail.state${(r.state != null ? r.state : 0)}`);
            this.addValueForChart(dataByKey, chartData.labels, colors, s);
          } else {
            // get report content
            const values = JSON.parse(CryptoHelper.decrypt(r.content)).assigned_values;
            if (fieldForChart.type === 'DD' || fieldForChart.type === 'CB') {
              const filteredValues = values.filter((f) => f.valueKey === fieldForChart.val);
              if (filteredValues.length === 1) {
                const [f] = filteredValues;
                this.addValueForChart(dataByKey, chartData.labels, colors, fieldForChart.type === 'CB' ? this.$t('charts.cb_true') : f.value);
              } else if (fieldForChart.type === 'CB') {
                this.addValueForChart(dataByKey, chartData.labels, colors, this.$t('charts.cb_false'));
              }
            } else if (fieldForChart.type === 'GROUP') {
              const filteredValues = values.filter((f) => this.checkboxGroupsByFieldNames[f.valueKey] === fieldForChart.val && (f.value === '1' || f.value === 1));
              if (filteredValues.length === 1) {
                const [f] = filteredValues;
                this.addValueForChart(dataByKey, chartData.labels, colors, this.fieldTitlesByKey[f.valueKey], this.fieldColorsByKey[f.valueKey]);
              } else {
                this.addValueForChart(dataByKey, chartData.labels, colors, this.$t('charts.cb_none'));
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      });
      chartData.labels.forEach((l) => {
        chartData.datasets[0].data.push(dataByKey[l].count);
      });
      chartData.datasets[0].backgroundColor = colors;
      this.$nextTick(() => {
        this.showChart = true;
        this.chartData = chartData;
      });
    },
    addValueForChart(chartDataByKey, labelsArray, colorsArray, value, color = null) {
      const cols = colorsArray;
      const cd = chartDataByKey;
      const la = labelsArray;
      if (cd[value] == null) {
        cd[value] = { label: value, count: 1 };
        la.push(value);
        if (color != null) {
          cols.push(color);
        } else {
          const colIdx = cols.length % this.defaultColors.length;
          cols.push(this.defaultColors[colIdx]);
        }
      } else {
        cd[value].count += 1;
      }
    },
  },
};
</script>

<style scoped>
.toggleOptions {
  padding: 12px;
  cursor: pointer;
  color: var(--color_orange);
}
.toggleOptions:hover {
  font-weight: 800;
}

.label, .value, button {
  vertical-align: top;
  margin-top: 4px;
  display: inline-block;
}

.label {
  margin-right: 4px;
}

.chartWrapper {
  margin: 32px 0px;
}

.chartSettings {
  display: inline-block;
  margin-right: 16px;
}

</style>
