<template>
  <div class="reportConfigContainer" >
    <div class="reportConfigs">
      <div v-if="!minimized" class="page-title">
        <router-link to="/reportConfigs"><div class='navButton narrow-screen'><i class="fas fa-arrow-left"/></div></router-link><span class="page-label">{{ $t('pageTitle.reports') }}</span>
        <div class="actionBar">
          <div class="actionButton bgBrown refresh" @click="loadReports(true)"><i class="fas fa-sync"></i>
            <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div></div>
          <div class="actionButton bgBrown refresh" @click="createCSV"><i class="fas fa-download"></i>
            <div class='actionButtonTitle'>CSV</div></div>
          <router-link v-if="reportConfig != null && reportConfig.isEditable" :to="'/edit/'+reportConfigId"><div class="actionButton bgBrown refresh"><i class="fas fa-pen"></i>
            <div class='actionButtonTitle'>{{ $t('action.designer') }}</div></div></router-link>
          <div v-if="$store.getters.userIsAdnim" class="actionButton bgBrown refresh" @click="editRights"><i class="fas fa-balance-scale-right"></i>
            <div class='actionButtonTitle'>{{ $t('action.rights') }}</div></div>
          <div class="actionButton bgBrown refresh" v-if="tenant.isAdminTenant" @click="dump"><i class="fas fa-poop"></i>
            <div class='actionButtonTitle'>dump</div></div>
          <div class="actionButton bgBrown refresh" @click="toggleShowCharts"><i class="fas fa-chart-bar"></i><div class='actionButtonTitle'>Chart</div></div>
          <router-link v-if="tenant.isAdminTenant && reportConfig != null" :to="'/workflow/'+reportConfigId"><div class="actionButton bgBrown refresh"><i class="fas fa-project-diagram"></i>
            <div class='actionButtonTitle'>{{ $t('action.workflow') }}</div></div></router-link>
        </div>
      </div>
      <div class="currentLocation" v-if='!minimized && reportConfigName != null'>
        <SearchSortComponent v-if="!showCharts" class="searchSort" :sortTypes="sortings" @searchTerm="searchText = $event; loadReports(true)" @sortType="activeSorting = $event; loadReports(true)"/>
        <router-link to="/reportConfigs"><div class='navButton wide-screen'><i class="fas fa-arrow-left"/> {{ $t('general.back') }}</div></router-link>
        <div class="currentLocationTitle">
          {{reportConfigName}}
        </div>
      </div>
      <br/>
      <div class="outerChartsWrapper" v-if="showCharts">
        <ReportChartsComponent ref="chartComponent" :reportConfig="reportConfig"/>
      </div>
      <div class="mainContentWrapper" v-else>
        <EmptyListAnimationComponent v-if="!loading && reports != null && reports.length == 0"/>
        <div v-if="!loading" class="listWrapper reportListWrapper" ref="reportListWrapper">
          <div class="listItemWrapper" v-for="r in reports.slice(pageIndexHelper * page * pageSize, pageIndexHelper * page * pageSize + pageSize)" :key="r._id">
            <div class="listItem" @click="openResponse(r)">
              <div class="icon">
                <i class="fas fa-clipboard-list"></i>
              </div>
              <span v-if="r.isIncomplete"><i class="warning fas fa-exclamation-triangle"/>&nbsp;</span> {{r.name}}
              <div v-if="!minimized" class="last-modified-label">
                [{{ $t('detail.modified') }}: {{ new Date(r.modified).toLocaleString() }}]
              </div>
              <div class="comments-label" v-if="!minimized && r.comments != null && r.comments > 0">
                [{{ r.comments }} {{ r.comments > 1 ? $t('detail.comments') : $t('detail.comment') }}]
              </div>
            </div>
            <div v-if="!minimized" class="listActionWrapper">
              <div class="stateSelector" :class="{ 'new': r.state == 0, 'in-progress': r.state == 1, 'done': r.state == 2, 'selectorOpened' : activeStateSwitching == r._id }"
                @click='activeStateSwitching != r._id ? activeStateSwitching = r._id : activeStateSwitching = ""'>
                <div class="stateSelectorSelection" v-if="r._id == activeStateSwitching">
                  <div v-for="(state) in [0, 1, 2]" :key="state" class="stateSelectorButtonHull"
                  @click.stop="changeState(r._id, state); activeStateSwitching != '' ? activeStateSwitching = '' : activeStateSwitching = r._id;">
                    <div class="stateSelectorButton"
                    :class="{ 'new': state == 0, 'in-progress': state == 1, 'done': state == 2, 'selected': state == r.state }">
                      {{ $t('detail.state' + state) }}
                    </div>
                  </div>
                </div>
                <span v-if='activeStateSwitching != r._id'>
                  <i class="fas fa-sign"/> <span class='actionTitle stateInfo'>{{ $t('detail.state' + r.state) }}</span>
                </span>
              </div>
              <span class="listAction" @click="deleteRow(r._id)" :title="$t('action.delete')"><i class="fas fa-trash"/></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <LoadingSpinnerIndicatorComponent />
    </div>
    <PaginationComponent class="paginator" v-if="reportCount > 0 && !showCharts"
      @page="page = $event; loadReports()"
      :elementCount="reportCount"
      @pageSize="pageSize = $event; loadReports()"
      :pageSizes="pageSizes"
      :elementLabels="['detail.report', 'detail.reports']"
    />
    <div class="error" v-if="errorMessage != null">{{errorMessage}}</div>
    <CreateOrEditReportConfigModal :reportConfigToEdit="reportConfigToEdit" ref="createOrEditReportConfigModal" @refresh="loadReports"/>
    <CSVExportModal ref="csvExportModal" :reportConfig="reportConfig" @performCSVExport="performCSVExport"/>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDelete')"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-underscore-dangle */

import CryptoHelper from '@/helpers/CryptoHelper';
import CSVHelper from '@/helpers/CSVHelper';
import ServiceHelper from '@/helpers/ServiceHelper';
import ScreenHeightMixin from '@/mixins/ScreenHeightMixin.vue';
import CSVExportModal from '@/components/modals/CSVExportModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import CreateOrEditReportConfigModal from '@/components/modals/CreateOrEditReportConfigModal.vue';
import LoadingSpinnerIndicatorComponent from './LoadingSpinnerIndicatorComponent.vue';
import EmptyListAnimationComponent from './EmptyListAnimationComponent.vue';
import PaginationComponent from './PaginationComponent.vue';
import SearchSortComponent from './SearchSortComponent.vue';
import ReportChartsComponent from './ReportChartsComponent.vue';

export default {
  components: {
    CSVExportModal,
    LoadingSpinnerIndicatorComponent,
    ConfirmModal,
    CreateOrEditReportConfigModal,
    EmptyListAnimationComponent,
    PaginationComponent,
    SearchSortComponent,
    ReportChartsComponent,
  },
  props: ['reportConfigId', 'reportConfigName', 'minimized'],
  name: 'ReportListComponent',
  mixins: [ScreenHeightMixin],
  data() {
    return {
      tenant: this.$store.getters.tenant,
      reports: [],
      reportConfig: null,
      loading: false,
      errorMessage: null,
      currentMoreMenu: null,
      reportToEdit: null,
      reportConfigToEdit: null,
      activeStateSwitching: '',
      activeSorting: localStorage.getItem('col_as') ?? 'modifiedDesc',
      open: false,
      sortings: ['createdAsc', 'createdDesc', 'modifiedAsc', 'modifiedDesc', 'state0', 'state1', 'state2', 'complete', 'incomplete'],
      searchText: '',
      page: 0,
      reportCount: 1,
      pageSize: 0,
      pageSizes: [12, 24, 48],
      pageIndexHelper: 0,
      showCharts: false,
    };
  },
  mounted() {
  },
  methods: {
    getUserName(user) {
      let name = '';
      if (user.firstname != null) name += CryptoHelper.decrypt(user.firstname);
      name += ' ';
      if (user.lastname != null) name += CryptoHelper.decrypt(user.lastname);
      return name.trim();
    },
    async loadReports(force) {
      this.reportConfig = (await ServiceHelper.loadDataFromBackend(`reportConfig/${this.reportConfigId}`)).payload;
      if (this.pageSize === 0) {
        return;
      }
      this.loading = true;
      this.errorMessage = null;
      try {
        if (this.searchText.trim() === '') {
          // store current active sorting for next visit
          localStorage.setItem('col_as', this.activeSorting);
          const data = await ServiceHelper.loadDataFromBackend(`reportNames/${this.reportConfigId}`, { page: this.page, limit: this.pageSize, sorting: this.activeSorting });
          // check for error response
          this.reports = data.payload.reports;
          this.reportCount = data.payload.total;
          this.pageIndexHelper = 0;
        } else if (force != null && force) {
          // store current active sorting for next visit
          localStorage.setItem('col_as', this.activeSorting);
          let step = 0;
          const stepSize = 20;
          let currentReports = 0;
          const lowerCasedSearch = this.searchText.toLowerCase();
          this.reports = [];
          /* eslint-disable no-await-in-loop */
          do {
            const data = await ServiceHelper.loadDataFromBackend(`reportNames/${this.reportConfigId}`, { page: step, limit: stepSize, sorting: this.activeSorting });
            currentReports = data.payload.reports.length;
            this.reports = this.reports.concat(data.payload.reports.filter((r) => CryptoHelper.decrypt(r.name).toString('utf-8').toLowerCase().indexOf(lowerCasedSearch) > -1
              || this.getUserName(r.modifier).toLowerCase().indexOf(lowerCasedSearch) > -1
              || this.getUserName(r.creator).toLowerCase().indexOf(lowerCasedSearch) > -1));
            step += 1;
          } while (currentReports === stepSize);
          /* eslint-enable no-await-in-loop */
          this.reportCount = this.reports.length;
          this.pageIndexHelper = 1;
        }
        if (this.searchText.trim() === '' || (force != null && force)) {
          this.reports.forEach((r) => {
            const report = r;
            const tmpTitle = CryptoHelper.decrypt(r.name).toString('utf-8');
            report.name = tmpTitle;
            if (report.state == null) {
              report.state = 0;
            }
            if (report.comments == null) {
              report.comments = 0;
            }
            report.modifierName = this.getUserName(report.modifier);
            report.creatorName = this.getUserName(report.creator);
          });
        }
      } catch (error) {
        this.loggedIn = false;
        this.token = null;
        console.error('There was an error!', error);
        this.errorMessage = error;
      } finally {
        this.loading = false;
        this.backendOperation = null;
      }
    },
    openResponse(r) {
      if (!this.minimized) {
        this.$router.push({
          name: 'ReportDetail',
          params: {
            reportId: r._id,
            reportName: r.name,
            reportConfigName: this.reportConfigName,
            reportConfigId: this.reportConfigId,
          },
        });
      } else {
        this.$emit('reportSelected', r);
      }
    },
    async deleteRow(selectedRowId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        await ServiceHelper.deleteDataFromBackend(`report/${selectedRowId}`);
        this.loadReports();
      }
    },
    async dump() {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < 500; i += 1) {
        const date = new Date().toLocaleString('ja-JA').replace('/', '-');
        const report = {
          reportConfigId: this.reportConfigId,
          name: CryptoHelper.encrypt(`Test ${i}`),
          content: CryptoHelper.encrypt(
            JSON.stringify({
              id: '/Report/t90BBF858-FFA8-44A8-88F8-8185917FDB459',
              entity: 'Report',
              timeOfModification: date,
              longitude: 0,
              latitude: 0,
              source: 0,
              assigned_values: [
                {
                  entity: 'ReportValue',
                  value: `Test ${i}`,
                  valueKey: 'Feld_004',
                },
              ],
              deleted: false,
              origin: 'TEST_DUMP',
              timeOfCreation: date,
              synched: 0,
            }),
          ),
          isIncomplete: false,
        };

        await ServiceHelper.sendDataToBackend('report', report);
      }
      /* eslint-enable no-await-in-loop */
    },
    async createCSV() {
      const rc = (await ServiceHelper.loadDataFromBackend(`reportConfig/${this.reportConfigId}`)).payload;
      const tmpTitle = CryptoHelper.decrypt(rc.name).toString('utf-8');
      rc.name = tmpTitle;
      const rightsData = await ServiceHelper.loadDataFromBackend(`rightsForReportConfig/${this.reportConfigId}`);
      rc.accessRights = rightsData.payload;
      this.reportConfig = rc;
      this.$refs.csvExportModal.show();
    },
    performCSVExport(startDate, endDate, fieldWrapper, fieldSeparator, lineSeparator, inFieldSeparator, language, filter) {
      try {
        CSVHelper.performCSVExport(this.reportConfigId, startDate, endDate, fieldWrapper, fieldSeparator, lineSeparator, inFieldSeparator, this.$t, language, filter);
      } catch (error) {
        console.error(error);
      }
    },
    async editRights() {
      const rc = (await ServiceHelper.loadDataFromBackend(`reportConfig/${this.reportConfigId}`)).payload;
      const tmpTitle = CryptoHelper.decrypt(rc.name).toString('utf-8');
      rc.name = tmpTitle;
      const rightsData = await ServiceHelper.loadDataFromBackend(`rightsForReportConfig/${this.reportConfigId}`);
      rc.accessRights = rightsData.payload;
      this.reportConfigToEdit = rc;
      this.$refs.createOrEditReportConfigModal.show();
    },
    async changeState(id, state) {
      await ServiceHelper.sendDataToBackend(`report/${id}/state`, { state });
      this.loadReports(true);
    },
    toggleShowCharts() {
      this.showCharts = !this.showCharts;
    },
    openWorkflow() {
      this.$router.push(`/workflow/${this.reportConfigId}`);
    },
  },
};
</script>

<style scoped>
@import url('../styles/tiles.module.css');
@import url('../styles/list.module.css');

.listWrapper .listItemWrapper:nth-child(odd) {
  background-color: var(--color_list_1);
}
.listWrapper .listItemWrapper:nth-child(even) {
  background-color: var(--color_list_2);
}

.listWrapper .listItemWrapper:hover {
  background-color: var(--color_orange_half);
}

.reportListWrapper {
  flex: 1 1 auto;
}

.last-modified-label {
  color: var(--color_brown);
  display: inline-block;
  font-size: 14px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  padding: 0px 12px;
}
.comments-label {
  color: var(--color_brown);
  display: inline-block;
  font-size: 14px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  padding: 0px 12px;
}

.warning {
  color: red;
}

.searchSort {
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 99;
}

.stateSelector {
  display: inline-block;
  color: black;
  cursor: pointer;
  padding: 6px 15px;
  border: 2px solid black;
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-right: 20px;
}
.stateSelector.new {
  color: var(--state-new-color);
  border-color: var(--state-new-color);
}
.stateSelector.in-progress {
  color: var(--state-in-progress-color);
  border-color: var(--state-in-progress-color);
}
.stateSelector.done {
  color: var(--state-done-color);
  border-color: var(--state-done-color);
}
.stateSelectorSelection {
  margin: -1px -10px;
  display: inline-block;
}
.stateSelectorSelected {
  display: inline-block;
}
.stateSelectorButtonHull {
  display: inline-block;
}
.stateSelectorButton {
  display: inline-block;
  padding: 0px 10px;
  margin: 0px 5px 0px 0px;
  border: 2px solid black;
  border-radius: 15px;
  color: black;
  font-size: 15px;
  font-weight: bold;
  line-height: 22px;
}
.stateSelectorButton.new {
  color: var(--state-new-color);
  border-color: var(--state-new-color);
}
.stateSelectorButton.in-progress {
  color: var(--state-in-progress-color);
  border-color: var(--state-in-progress-color);
}
.stateSelectorButton.done {
  color: var(--state-done-color);
  border-color: var(--state-done-color);
}
.stateSelectorButton.selected {
  color: var(--color_almost_white);
}
.stateSelectorButton.selected.new {
  background-color: var(--state-new-color);
}
.stateSelectorButton.selected.in-progress {
  background-color: var(--state-in-progress-color);
}
.stateSelectorButton.selected.done {
  background-color: var(--state-done-color);
}

.listWrapper .listItemWrapper .listItem {
  width: calc(100% - 230px);
}

.paginator {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.outerChartsWrapper {
  overflow-y: auto;
}

.reportConfigContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.repro {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
}

.reportConfigContainer .page-title, .reportConfigContainer .currentLocation, .reportConfigContainer.paginator {
  flex: 0 1 auto;
  min-height: 0%;
}

.reportConfigs, .reportConfigs .mainContentWrapper {
  flex: 1 1 auto;
}

.reportConfigs .mainContentWrapper {
  min-height: 0%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.reportConfigs {
  overflow-y: auto;
}

@media (max-width: 800px) {
  .searchSort {
    position: relative;
    top: 0px;
    right: 0;
    left: 0;
    margin: 0px 0px 16px 0px;
    z-index: 99;
    display: block;
    width: auto;
  }

  .stateSelector .stateInfo {
    display: none;
  }
  .stateSelector.selectorOpened {
    background-color: rgba(255,255,255,0.8);
  }
  .stateSelectorButton {
    margin-bottom: 8px;
  }
  .last-modified-label {
    display: none;
  }
  .comments-label {
    display: none;
  }

  .listWrapper .listItemWrapper .listItem {
    width: calc(100% - 20px);
  }

}

</style>
