<template>
  <CustomModal v-model="showCsvExportModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{ $t('modal.labelExportCVS') }}</template>
    <div class="form">
      <div class="row">
        <div class="label">{{ $t('modal.labelStartDate') }}:</div>
        <div class="value">
          <Datepicker v-model="startDate"/>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelEndDate') }}:</div>
        <div class="value">
          <Datepicker v-model="endDate"/>
        </div>
      </div>
      <div class="row" v-if="languages != {}">
        <div class="label">{{ $t('modal.exportLanguage') }}:</div>
        <div class="value">
          <select v-model="language">
            <option v-for='(language, idx) in languages' :key="idx" :value="language">{{ languageLabels[language] }} ({{ language.toUpperCase() }})</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.exportFilter') }}:</div>
        <div class="value">
          <select v-model="filter">
            <option value="">{{ $t('sorting.all') }}</option>
            <option value="state0">{{ $t('sorting.state0') }}</option>
            <option value="state1">{{ $t('sorting.state1') }}</option>
            <option value="state2">{{ $t('sorting.state2') }}</option>
            <option value="complete">{{ $t('sorting.complete') }}</option>
            <option value="incomplete">{{ $t('sorting.incomplete') }}</option>
          </select>
        </div>
      </div>
      <div v-if="showExportOptions">
        <hr/>
        <div class="row">
          <div class="label">{{ $t('modal.labelFieldSeparator') }}:</div>
          <div class="value">
            <input type="text" v-model="fieldSeparator"/>
          </div>
        </div>
        <div class="row">
          <div class="label">{{ $t('modal.labelLineSeperator') }}:</div>
          <div class="value">
            <input type="text" v-model="lineSeparator"/>
            <div class="hint">{{ $t('modal.hintLineSeperator') }}</div>
          </div>
        </div>
        <div class="row">
          <div class="label">{{ $t('modal.labelFieldValueStartEnd') }}:</div>
          <div class="value">
            <input type="text" v-model="valueWrapper"/>
          </div>
        </div>
        <div class="row">
          <div class="label">{{ $t('modal.labelInCellListSeparator') }}:</div>
          <div class="value">
            <input type="text" v-model="inFieldSeparator"/>
            <div class="hint">{{ $t('modal.hintInCellListSeparator') }}</div>
          </div>
        </div>
        <div class="toggleOptions" @click="showExportOptions = false">
          <i class="fas fa-arrow-up"/>&nbsp;{{ $t('modal.labelOptions') }}
        </div>
      </div>
      <div v-else class="toggleOptions" @click="showExportOptions = true">
          <i class="fas fa-arrow-down"/>&nbsp;{{ $t('modal.labelOptions') }}
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CryptoHelper from '@/helpers/CryptoHelper';
import CustomModal from '@/components/modals/CustomModal.vue';
import Datepicker from 'vue3-datepicker';
import LanguageHelper from '@/helpers/LanguageHelper';

export default {
  name: 'CSVExportModal',
  props: ['reportConfig'],
  components: {
    CustomModal,
    Datepicker,
  },
  data() {
    return {
      showCsvExportModal: false,
      startDate: new Date(),
      endDate: new Date(),
      valueWrapper: '"',
      fieldSeparator: ';',
      inFieldSeparator: '<SPACE>',
      lineSeparator: '<NEWLINE>',
      showExportOptions: false,
      languages: [],
      languageLabels: LanguageHelper.getStandardReportLanguages(),
      language: LanguageHelper.getBrowserLocale(),
      filter: '',
    };
  },
  mounted() {
    this.startDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  },
  watch: {
    reportConfig(val) {
      const config = JSON.parse(CryptoHelper.decrypt(val.content).toString('utf-8'));
      this.language = config != null ? config.languageFallback : LanguageHelper.getBrowserLocale();
      this.languages = config != null ? config.languages : [];
    },
  },
  methods: {
    show() {
      this.showCsvExportModal = true;
    },
    hide() {
      this.showCsvExportModal = false;
    },
    cancel() {
      this.hide();
    },
    confirm() {
      const startDate = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), 0, 0, 0));
      const endDate = new Date(Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 23, 59, 59));
      this.$emit(
        'performCSVExport',
        startDate,
        endDate,
        this.valueWrapper,
        this.fieldSeparator,
        this.lineSeparator.replace('<NEWLINE>', '\n'),
        this.inFieldSeparator.replace('<SPACE>', ' '),
        this.language,
        this.filter,
      );
      this.hide();
    },
  },
};
</script>

<style scoped>
.toggleOptions {
  padding: 12px;
  cursor: pointer;
  color: var(--color_orange);
}
.toggleOptions:hover {
  font-weight: 800;
}

::v-deep(.modal_content .label) {
  vertical-align: top;
  margin-top: 4px;
}
</style>
